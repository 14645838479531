/* Base font declarations */

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/fonts/SF-Pro-Display-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/fonts/SF-Pro-Display-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/fonts/SF-Pro-Display-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/fonts/SF-Pro-Display-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Loretta";
  src: url("../public/fonts/Loretta-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

/* Password Gate styles */
.password-gate {
  position: fixed;
  inset: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.password-gate-container {
  width: 100%;
  max-width: 300px;
  padding: 0 24px;
}

.password-gate-header {
  margin-bottom: 40px;
  text-align: left;
}

.password-error-container {
  height: 20px;
  margin-bottom: 8px;
  align-items: center;
}

.password-error {
  color: #ff4d4f;
  font-family: "SF Pro Display";
  font-size: 16px;
}

.password-input {
  width: 100%;
  height: 56px;
  padding: 0 16px;
  background-color: white;
  border: 1px solid #9291a2;
  border-radius: 13px;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px; /* Updated from 8px to 16px */
  box-sizing: border-box;
}

.password-input-error {
  border-color: #ff4d4f;
}

.password-input::placeholder {
  color: #9a9a9a;
  font-weight: 400;
}

.password-input:focus {
  outline: none;
}

.password-submit {
  width: 100%;
  height: 48px;
  background-color: #1a1a1a;
  color: white;
  border-radius: 13px;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-sizing: border-box;
}

.password-submit:hover {
  background-color: #333;
}

.password-submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Animation for projects grid */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  padding: 40px 0;
}

.project img,
.project video {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2; /* Changed to 3:2 ratio (wider than tall) */
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 16px;
}

/* Modal styles */
.modal-image {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2; /* Changed to 3:2 ratio to match grid view */
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  display: block;
}

.fonts-loaded .projects-grid {
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 0.1s;
}

/* Base styles */
body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display", system-ui;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 24px;
}

/* Header styles */
header {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header-left h1 {
  font-family: "SF Pro Display";
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0225rem;
  color: #1a1a1a;
  margin: 0;
}

.location {
  font-family: "SF Pro Display";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03375rem;
  color: #999;
  margin: 4px 0 0 0;
}

.mobile-menu {
  display: none;
}

.menu-icon {
  width: 24px;
  height: 24px;
  color: #1a1a1a;
}

.desktop-nav a {
  font-family: "SF Pro Display";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03375rem;
  color: #1a1a1a;
  text-decoration: none;
  margin-left: 24px;
}

.desktop-nav a:hover {
  border-bottom: 1px solid #1a1a1a;
  padding-bottom: 2px;
}

/* Intro section */
.intro {
  padding: 80px 0;
}

.intro h2 {
  font-family: "Loretta";
  font-size: 2rem;
  font-weight: 300;
  line-height: 133%;
  letter-spacing: 0.04rem;
  color: #1a1a1a;
  max-width: 700px;
  margin: 0;
}

.intro p {
  font-size: 20px;
  color: #666;
  max-width: 800px;
  margin: 16px 0 0 0;
}

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  padding: 40px 0;
}

.project img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 16px;
}

.project h3 {
  font-family: "SF Pro Display";
  font-size: 1rem;
  font-weight: 400;
  line-height: 133%;
  letter-spacing: 0.03rem;
  color: #1a1a1a;
  margin: 0;
}

.project p {
  font-family: "SF Pro Display";
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03375rem;
  color: #999;
  margin: 4px 0 0 0;
}

/* Footer styles */
footer {
  margin-top: 80px;
}

.footer-border {
  border-top: 1px solid #dddddd;
  padding-top: 40px;
}

.footer-nav {
  display: flex;
  gap: 24px;
}

.footer-nav a {
  font-family: "SF Pro Display";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03375rem;
  color: #999;
  text-decoration: none;
  padding-bottom: 2px;
}

.footer-nav a:hover {
  border-bottom: 1px solid #999;
  padding-bottom: 2px;
}

/* Project styles */
.project-wrapper {
  cursor: pointer;
}

.project {
  transition: transform 0.2s ease;
  margin-bottom: 16px;
}

.project-wrapper:hover .project {
  transform: scale(1.02);
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
}

.project-info h3 {
  font-family: "SF Pro Display";
  font-size: 1rem;
  font-weight: 400;
  line-height: 133%;
  letter-spacing: 0.03rem;
  color: #1a1a1a;
  margin: 0;
}

.project-info p {
  font-family: "SF Pro Display";
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03375rem;
  color: #999;
  margin: 4px 0 0 0;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 24px;
  opacity: 0;
  transition: background-color 0.2s ease-out, opacity 0.2s ease-out;
}

.modal-overlay-enter {
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 1;
}

.modal-overlay-exit {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  position: relative;
  opacity: 0;
  transform: scale(0.95) translateY(10px);
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  overflow-y: auto;
}

.modal-content-enter {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.modal-content-exit {
  opacity: 0;
  transform: scale(0.95) translateY(10px);
}

.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #999;
  z-index: 1;
}

.modal-close:hover {
  color: #1a1a1a;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
  display: block;
}

.modal-info {
  padding: 32px;
}

.modal-info h2 {
  font-family: "SF Pro Display";
  font-size: 1rem;
  font-weight: 400;
  line-height: 133%;
  letter-spacing: 0.03rem;
  color: #1a1a1a;
  margin: 0;
}

.modal-description {
  font-family: "SF Pro Display";
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.035rem;
  color: #646464;
  margin: 8px 0 0 0;
  max-width: 500px;
}

/* Mobile menu styles */
.mobile-menu {
  display: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  z-index: 1001;
}

.menu-icon {
  width: 24px;
  height: 24px;
  position: relative;
  display: block;
}

/* Menu lines base styles */
.menu-line {
  display: block;
  width: 24px;
  height: 1.5px;
  background-color: #1a1a1a;
  position: absolute;
  left: 0;
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.menu-line.top {
  top: 11px; /* Center line position */
  transform-origin: center;
}

.menu-line.middle {
  top: 11px; /* Center line position */
  transform-origin: center;
}

.menu-line.bottom {
  top: 11px; /* Center line position */
  transform-origin: center;
}

/* Initial hamburger position - increased spacing */
.menu-icon:not(.menu-icon-open) .menu-line.top {
  transform: translateY(-7px); /* Increased from -5px */
}

.menu-icon:not(.menu-icon-open) .menu-line.bottom {
  transform: translateY(7px); /* Increased from 5px */
}

/* Menu icon animation for open state */
.menu-icon-open .menu-line.top {
  transform: rotate(45deg);
}

.menu-icon-open .menu-line.middle,
.menu-icon-open .menu-line.bottom {
  transform: rotate(-45deg);
}

/* Mobile menu overlay */
.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.mobile-menu-open {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-content {
  padding: 100px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
}

.mobile-menu-open .mobile-menu-content {
  opacity: 1;
  transform: translateY(0);
}

.mobile-menu-content a {
  font-family: "SF Pro Display";
  font-size: 1.875rem;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.03375rem;
  color: #1a1a1a;
  text-decoration: none;
  transition: opacity 0.2s ease;
  width: fit-content;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 2px;
}

.mobile-menu-content a:hover {
  border-bottom: 1px solid #1a1a1a;
  padding-bottom: 2px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .mobile-menu {
    display: block;
  }

  .desktop-nav {
    display: none;
  }

  .modal-overlay {
    padding: 24px 8px;
  }

  .modal-content {
    border-radius: 8px;
    height: auto;
    max-height: 85vh;
    transform: scale(0.95) translateY(10px);
  }

  .modal-content-enter {
    transform: scale(1) translateY(0);
  }

  .modal-content-exit {
    transform: scale(0.95) translateY(10px);
  }

  .modal-image {
    border-radius: 8px 8px 0 0;
  }

  .modal-info {
    padding: 24px;
  }

  .modal-info h2 {
    /* Match the project title size to landing page */
    font-family: "SF Pro Display";
    font-size: 1rem;
    font-weight: 400;
    line-height: 133%;
    letter-spacing: 0.03rem;
    color: #1a1a1a;
    margin: 0;
  }

  .modal-description {
    /* Match the description size to landing page */
    font-family: "SF Pro Display";
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.04rem;
    color: #646464;
    max-width: none;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 24px;
  }

  .header-left h1 {
    font-size: 0.9rem;
    letter-spacing: 0.018rem;
  }

  .location {
    font-size: 0.9rem;
    letter-spacing: 0.027rem;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-menu {
    display: block;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .intro {
    padding: 40px 0;
  }

  .intro h2 {
    font-size: 1.25rem;
    letter-spacing: 0.025rem;
    line-height: 133%;
  }

  .projects-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 64px;
  }

  .project h3 {
    font-size: 0.875rem;
    letter-spacing: 0.02625rem;
    line-height: 133%;
  }

  .project p {
    font-size: 0.9rem;
    letter-spacing: 0.027rem;
  }

  footer {
    margin-top: 60px;
    padding-bottom: 40px;
  }

  .footer-nav a {
    font-size: 0.9rem;
    letter-spacing: 0.027rem;
  }

  /*Password gate*/
  .password-error {
    font-size: 14px;
  }

  .password-error-container {
    height: 16px;
    margin-bottom: 8px;
  }

  .password-gate-header {
    margin-bottom: 44px;
  }

  .password-submit {
    font-size: 14px;
    letter-spacing: 0.35px;
  }
}
